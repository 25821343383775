.sidebar {
  width: 250px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 0.5px solid rgb(230, 227, 227);
  background-color: white;
  position: fixed;
  scrollbar-width: none;
  &:hover {
    scrollbar-width: thin;
  }

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .logo {
      font-size: 20px;
      font-weight: bold;
    }
    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    flex: 1; /* Allow center to grow and occupy remaining space */
    padding-right: 10px; /* Padding is on the right side for rtl */

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      .link {
        display: flex;
        align-items: center;
        padding: 8px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          background-color: rgba(255, 97, 29, 0.3);
        }
        &.active {
          background-color: rgba(255, 97, 29, 0.3);
          li {
            padding-left: 24px; /* Padding is on the left side for rtl */
            transition: 0.3s;
            span {
              color: #3a3741;
            }
          }
          transition: 0.3s;
        }
        .icon {
          font-size: 18px;
          color: #ff611d;
        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #888;
          margin-right: 10px; /* Margin is on the right side for rtl */
        }
        li {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #ff611d;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

@media (max-width: 1024px) {
  .sidebar {
    width: 50px !important;
    .center {
      padding-right: 10px; /* Padding is on the right side for rtl */

      ul {
        width: 100%;

        .title {
          width: 100%;
          text-align: center;
        }

        .link {
          span {
            display: none;
          }
          &.active {
            li {
              padding-left: 16px; /* Padding is on the left side for rtl */
            }
          }
        }
      }
    }
  }
}
