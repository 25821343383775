.banner {
  .container {
    width: calc(100% - 140px);
    padding: 16px 70px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .action-container {
      .action-btn {
        padding: 5px 8px;
        border-radius: 10px;
        border: none;
        outline: none;
        background-color: #ff611d;
        color: white;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .banners-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 16px 0px;
      gap: 16px;
      .single-banner {
        width: calc(48% - 56px);
        border-radius: 5px;
        padding: 28px;
        background-color: white;
        display: flex;
        flex-direction: column;
        .banner-image {
          width: 100%;
          height: 240px;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .banner-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 12px;
          p {
            color: #acacac;
            font-size: 17px;
            font-weight: 600;
          }
          .action-container {
            display: flex;
            align-items: center;
            gap: 24px;
            .edit {
              width: 56px;
              height: 56px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
              cursor: pointer;
              .icon {
                font-size: 32px;
                color: #f9a636;
              }
            }
            .remove {
              width: 56px;
              height: 56px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
              cursor: pointer;
              &:hover {
                background-color: #fff1eb;
              }
              .icon {
                font-size: 32px;
                color: #bc2a46;
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 655px;
    padding: 46px 27px;
    border-radius: 10px;
    background-color: white;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    .form-control {
      width: 100%;
      margin-bottom: 18px;
      position: relative;
      input {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background: #f5f5f5;
        color: transparent;
        &::-webkit-file-upload-button {
          display: none;
        }
      }

      .test {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 0px;
        label {
          padding: 0px 8px;
          background-color: white;
          color: #979797;
          border: 2px solid #979797;
          border-radius: 10px;
          font-size: 17px;
          cursor: pointer;
          font-weight: 600;
          text-align: center;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        span {
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    p {
      width: 100%;
      font-size: 17px;
      font-weight: 600;
      color: #acacac;
      text-align: right;
      margin-bottom: 40px;
    }
    .form-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .action-btn {
        width: 208px;
        height: 50px;
        padding: 10px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        color: #ff611d;
        background-color: #fff1eb;
        outline: none;
        border: none;
        cursor: pointer;
        &:first-child {
          background-color: white;
          color: #979797;
          border: 2px solid #979797;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    .container {
      width: calc(100% - 32px);
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .action-container {
        .action-btn {
          width: fit-content;
          padding: 0px 8px;
          border-radius: 10px;
          border: none;
          outline: none;
          background-color: #ff611d;
          color: white;
          font-size: 16px;
          font-weight: 500;
          line-height: 40px;
          cursor: pointer;
        }
      }
      .banners-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        padding: 16px 0px;
        gap: 8px !important;
        .single-banner {
          width: calc(100% - 16px) !important;
          border-radius: 5px;
          padding: 8px;
          background-color: white;
          display: flex;
          flex-direction: column;
          .banner-image {
            width: 100%;
            height: 200px;
            border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover !important;
              border-radius: 5px;
            }
          }
          .banner-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 12px;
            p {
              color: #acacac;
              font-size: 12px;
              font-weight: 600;
            }
            .action-container {
              display: flex;
              align-items: center;
              gap: 24px;
              .edit {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                cursor: pointer;
                .icon {
                  font-size: 32px;
                  color: #f9a636;
                }
              }
              .remove {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                cursor: pointer;
                &:hover {
                  background-color: #fff1eb;
                }
                .icon {
                  font-size: 32px;
                  color: #bc2a46;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    .form {
      width: 90% !important;
      padding: 16px;
      border-radius: 10px;
      background-color: white;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      .form-control {
        width: 100%;
        margin-bottom: 18px;
        position: relative;
        input {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background: #f5f5f5;
          color: transparent;
          &::-webkit-file-upload-button {
            display: none;
          }
        }

        .test {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          padding: 0px;
          label {
            padding: 0px 8px;
            background-color: white;
            color: #979797;
            border: 2px solid #979797;
            border-radius: 10px;
            font-size: 17px;
            cursor: pointer;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          span {
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      p {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        color: #acacac;
        text-align: right;
        margin-bottom: 40px;
      }
      .form-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .action-btn {
          width: 160px;
          height: 40px;
          padding: 0px;
          border-radius: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #ff611d;
          background-color: #fff1eb;
          outline: none;
          border: none;
          cursor: pointer;
          &:first-child {
            background-color: white;
            color: #979797;
            border: 2px solid #979797;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .banner {
    .container {
      width: calc(100% - 32px);
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .action-container {
        .action-btn {
          width: fit-content;
          padding: 0px 8px;
          border-radius: 10px;
          border: none;
          outline: none;
          background-color: #ff611d;
          color: white;
          font-size: 16px;
          font-weight: 500;
          line-height: 40px;
          cursor: pointer;
        }
      }
      .banners-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        padding: 16px 0px;
        gap: 8px !important;
        .single-banner {
          width: calc(50% - 26px);
          border-radius: 5px;
          padding: 8px;
          background-color: white;
          display: flex;
          flex-direction: column;
          .banner-image {
            width: 100%;
            height: 200px;
            border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 5px;
            }
          }
          .banner-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 12px;
            p {
              color: #acacac;
              font-size: 12px;
              font-weight: 600;
            }
            .action-container {
              display: flex;
              align-items: center;
              gap: 24px;
              .edit {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                cursor: pointer;
                .icon {
                  font-size: 32px;
                  color: #f9a636;
                }
              }
              .remove {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                cursor: pointer;
                &:hover {
                  background-color: #fff1eb;
                }
                .icon {
                  font-size: 32px;
                  color: #bc2a46;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    .form {
      width: 90% !important;
      padding: 16px;
      border-radius: 10px;
      background-color: white;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      .form-control {
        width: 100%;
        margin-bottom: 18px;
        position: relative;
        input {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background: #f5f5f5;
          color: transparent;
          &::-webkit-file-upload-button {
            display: none;
          }
        }

        .test {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          padding: 0px;
          label {
            padding: 0px 8px;
            background-color: white;
            color: #979797;
            border: 2px solid #979797;
            border-radius: 10px;
            font-size: 17px;
            cursor: pointer;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          span {
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      p {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        color: #acacac;
        text-align: right;
        margin-bottom: 40px;
      }
      .form-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .action-btn {
          width: 160px;
          height: 40px;
          padding: 0px;
          border-radius: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #ff611d;
          background-color: #fff1eb;
          outline: none;
          border: none;
          cursor: pointer;
          &:first-child {
            background-color: white;
            color: #979797;
            border: 2px solid #979797;
          }
        }
      }
    }
  }
}
