.chart {
  flex: 4;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  background-color: white;
  .title {
    margin-bottom: 20px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}

@media (max-width: 1024px) {
  .chart {
    width: 100%;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 5px;
    color: gray;
    background-color: white;
    .title {
      margin-bottom: 20px;
      font-size: 12px;
    }

    .chartGrid {
      stroke: rgb(228, 225, 225);
    }
    .name {
      font-size: 6px;
      font-weight: 800;
    }
  }
}
