.navbar {
  position: fixed;
  margin-bottom: 50px;
  height: 50px;
  z-index: 10000;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  width: 100%;
  background-color: white;
  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
      }
    }
    .logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .language {
      border: none !important;
      outline: none !important;
      .ReactFlagsSelect-module_selectBtn__19wW7 {
        border: none !important;
      }
      .ReactFlagsSelect-module_label__27pw9 {
        padding-right: 10px;
      }
    }
  }
}
