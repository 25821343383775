.admin-container {
  width: 100% !important;
  .admin-profile {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .header-container {
      position: relative;
      width: calc(100% - 2px);
      height: 260px;
      border: 1px solid #ccc;
      margin-bottom: 100px;
      .image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .admin-nav {
        position: absolute;
        width: 100%;
        min-height: 90px;
        background-color: rgba(255, 255, 255, 0.6);
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wrapper {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 50%;
          margin: 0px 30px;
        }
      }
    }

    .info-container {
      width: 80%;
      background-color: white;
      padding: 50px;
      border-radius: 8px;
      margin-bottom: 50px;
      .loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      form {
        h2 {
          margin-bottom: 20px;
        }
        .control-group {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          gap: 30px;
          .remove {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            &:hover {
              color: red;
            }
          }

          .form-control {
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            gap: 5px;
            input {
              padding: 10px;
              border-radius: 6px;
              border: 1px solid #aaa;
              outline: none;
              flex: 1;
              font-size: 18px;
              font-weight: 600;
              color: black;
            }

            label {
              position: absolute;
              top: -14px;
              color: gray;
              font-size: 14px;
              font-weight: 600;
              background-color: white;
            }
          }
          .icon-container {
            width: 46px;
            height: 46px;

            img {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }
        }

        .form-action {
          button {
            width: 160px;
            flex: 1;
            font-size: 16px;
            padding: 5px 20px;
            border-radius: 6px;
            outline: none;
            border: none;
            background-color: #ff611d;
            color: white;
            cursor: pointer;
          }
        }

        .form-actions {
          width: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          gap: 5px;

          .form-action {
            button {
              width: 160px;
              flex: 1;
              font-size: 16px;
              padding: 5px 20px;
              border-radius: 6px;
              outline: none;
              border: none;
              background-color: #ff611d;
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.progress-container {
  position: fixed;
  width: 200% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

@media (max-width: 1024px) {
  .admin-container {
    width: 100% !important;
    .admin-profile {
      width: 100% !important;
      display: flex;
      align-items: center;
      flex-direction: column;

      position: relative;
      .header-container {
        position: relative;
        width: 100% !important;
        height: 260px;
        border: 1px solid #ccc;
        margin-bottom: 100px;
        .image-container {
          position: absolute;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .admin-nav {
          position: absolute;
          width: 100%;
          min-height: 90px;
          background-color: rgba(255, 255, 255, 0.6);
          bottom: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          .wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            h3 {
              font-size: 10px;
            }
            .name {
              display: none;
            }
          }

          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin: 0px 30px;
          }
        }
      }

      .info-container {
        width: calc(100% - 32px);
        background-color: white;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 50px;
        .loader {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        form {
          width: 100% !important;
          display: flex;

          flex-direction: column;
          h2 {
            margin-bottom: 20px;
          }

          .control-group {
            width: 100% !important;
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 30px;
            gap: 30px;
            .remove {
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              cursor: pointer;
              &:hover {
                color: red;
              }
            }

            .form-control {
              width: 100%;
              position: relative;
              flex: 1;
              display: flex;
              align-items: center;
              gap: 5px;
              input {
                width: calc(100% - 20px);
                padding: 10px;
                border-radius: 6px;
                border: 1px solid #aaa;
                outline: none;
                flex: 1;
                font-size: 18px;
                font-weight: 600;
                color: black;
              }

              label {
                position: absolute;
                top: -16px;
                color: gray;
                font-size: 14px;
                font-weight: 600;
                background-color: white;
              }
            }

            .image {
              width: 100% !important;
            }
            .icon-container {
              width: 46px;
              height: 46px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .form-action {
            button {
              width: 120px;
              flex: 1;
              font-size: 16px;
              padding: 0px;
              height: 40px;
              border-radius: 6px;
              outline: none;
              border: none;
              background-color: #ff611d;
              color: white;
              cursor: pointer;
            }
          }

          .form-actions {
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            .form-action {
              button {
                width: 120px;
                flex: 1;
                font-size: 16px;
                padding: 0px;
                height: 40px;
                border-radius: 6px;
                outline: none;
                border: none;
                background-color: #ff611d;
                color: white;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
