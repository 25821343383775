.test {
  width: calc(100% - 32px);
  height: 100%;
  padding: 20px 16px !important;
  div.css-z8fhq1-MuiDataGrid-columnHeaders {
    background-color: #fff4f0;
  }
  div.css-1w5m2wr-MuiDataGrid-virtualScroller {
  }
  div.MuiDataGrid-footerContainer {
  }
  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    gap: 15px;

    .add-button {
      width: 157px;
      text-align: center;
      text-decoration: none;
      color: #ff611d !important;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #ff611d !important;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        color: white !important;
        background-color: #ff611d !important;
      }
    }
  }
  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
    .edit-button {
      text-decoration: none;
      width: 56px;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
      color: #f9a636;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
      &:hover {
        background-color: #f9a636;
        color: #fff;
        border: none;
      }
    }

    .delete-button {
      width: 56px;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
      &:hover {
        background-color: crimson;
        color: #fff;
        border: none;
      }
    }
  }
  .data-grid {
  }
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  form {
    width: 655px !important;
    padding: 24px;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px !important;
    h3 {
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      line-height: 37.48px;
      margin-bottom: 5px;
      text-align: right;
    }
    .control-group {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      label {
        display: block;
        position: absolute;
        top: -16px;
        left: 16px;
        font-size: 14px;
        font-weight: 500;
        color: grey;
      }
      .form-control {
        border-radius: 10px;
        width: 100%;
        position: relative;
        label {
          display: block;
          position: absolute;
          top: -14px;
          left: 10px;
          font-size: 14px;
          font-weight: 500;
        }
        input {
          width: calc(100% - 20px);
          height: 50px;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: none;
          outline: none;
          font-size: 17px;
          font-weight: 600;
          line-height: 50px;
          padding-right: 10px;
          padding-left: 10px;
          display: flex;
        }
      }

      select {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: #f5f5f5;
        font-size: 17px;
        font-weight: 600;
        line-height: 31.86px;
        padding-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline-color: #2684ff;
      }

      textarea {
        width: 100%;
        min-height: 80px;
        max-height: 80px;
        top: 433px;
        border-radius: 10px;
        padding: 10px;
        background-color: #f5f5f5;
        border: none;
        outline: none;
        font-size: 17px;
        font-weight: 600;
      }
    }
    .react-selector {
      width: 100%;
      border: none;
      outline: none;
      .css-13cymwt-control {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: #f5f5f5;
        border: none;
        outline: none;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .css-1xc3v61-indicatorContainer {
        // display: none;
        color: black;
      }
      .css-1jqq78o-placeholder {
        font-size: 17px;
        font-weight: 600;
      }

      .css-1hb7zxy-IndicatorsContainer {
        margin-left: 4px;
        .css-tj5bde-Svg {
          width: 24px;
          height: 24px;
          color: #515151;
        }
      }
    }

    .form-action {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      gap: 15px;
      .action-btn {
        width: 208px;
        height: 54px;
        padding: 10px;
        color: #ff611d;
        background-color: #fff1eb;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 33.73px;
        text-align: center;
        cursor: pointer;
      }
      .action-btn-cancel {
        font-size: 18px;
        font-weight: 600;
        line-height: 33.73px;
        text-align: center;
        background-color: white;
        width: 208px;
        height: 54px;
        padding: 10px;
        color: #979797;
        border-radius: 10px;
        cursor: pointer;
        outline: none;
        border: 2px solid #979797;
      }
    }
  }
}

.message-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .message {
    padding: 50px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        color: #ff611d;
        font-size: 30px;
      }
      p {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .action-container {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        width: 208px;
        height: 54px;
        padding: 10px;
        color: #ff611d;
        background-color: #fff1eb;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 33.73px;
        text-align: center;
        cursor: pointer;
        &:last-child {
          background-color: white;
          color: #979797;
          border: 2px solid #979797;
        }
      }
    }
    button {
      width: 208px;
      height: 54px;
      padding: 10px;
      color: #ff611d;
      background-color: #fff1eb;
      border-radius: 10px;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: 600;
      line-height: 33.73px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.progress {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .test {
    padding: 8px !important;
    width: calc(100% - 16px);
    div.css-z8fhq1-MuiDataGrid-columnHeaders {
      background-color: #fff4f0;
    }
    div.MuiDataGrid-footerContainer {
    }
    .datatableTitle {
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      gap: 15px;

      .add-button {
        width: 120px;
        text-align: center;
        text-decoration: none;
        color: #ff611d !important;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #ff611d !important;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          color: white !important;
          background-color: #ff611d !important;
        }
      }
    }
    .cellWithImg {
      display: flex;
      align-items: center;

      .cellImg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .cellAction {
      display: flex;
      align-items: center;
      // flex-direction: column;
      gap: 5px;

      .edit-button {
        text-decoration: none;
        width: 50px;
        text-align: center;
        padding: 2px;
        border-radius: 5px;
        color: #f9a636;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
        &:hover {
          background-color: #f9a636;
          color: #fff;
          border: none;
        }
      }

      .delete-button {
        width: 50px;
        text-align: center;
        padding: 2px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
        &:hover {
          background-color: crimson;
          color: #fff;
          border: none;
        }
      }
    }
    .data-grid {
    }
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-top: 50px;
    width: 90%;
    height: 90%;
    form {
      width: 100% !important;
      padding: 16px;
      border-radius: 10px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px !important;
      h3 {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 37.48px;
        margin-bottom: 5px;
        text-align: right;
      }
      .control-group {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        position: relative;
        label {
          display: block;
          position: absolute;
          top: -16px;
          left: 16px;
          font-size: 14px;
          font-weight: 500;
          color: grey;
        }
        .form-control {
          border-radius: 10px;
          width: 95%;
          position: relative;
          label {
            display: block;
            position: absolute;
            top: -14px;
            left: 10px;
            font-size: 14px;
            font-weight: 500;
          }
          input {
            width: calc(100% - 20px);
            height: 50px;
            border-radius: 10px;
            background-color: #f5f5f5;
            border: none;
            outline: none;
            font-size: 17px;
            font-weight: 600;
            line-height: 31.86px;
            padding-right: 10px;
            display: flex;
          }
        }

        select {
          width: 95%;
          height: 50px;
          border-radius: 10px;
          background-color: #f5f5f5;
          font-size: 17px;
          font-weight: 600;
          line-height: 31.86px;
          padding-right: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          outline-color: #2684ff;
        }

        textarea {
          width: calc(95% - 20px);
          min-height: 40px;
          max-height: 40px;
          top: 433px;
          border-radius: 10px;
          padding: 10px;
          background-color: #f5f5f5;
          border: none;
          outline: none;
          font-size: 17px;
          font-weight: 600;
        }
      }
      .react-selector {
        width: 95%;
        border: none;
        outline: none;
        .css-13cymwt-control {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: none;
          outline: none;
        }

        .css-1u9des2-indicatorSeparator {
          display: none;
        }
        .css-1xc3v61-indicatorContainer {
          // display: none;
          color: black;
        }
        .css-1jqq78o-placeholder {
          font-size: 17px;
          font-weight: 600;
        }

        .css-1hb7zxy-IndicatorsContainer {
          margin-left: 4px;
          .css-tj5bde-Svg {
            width: 24px;
            height: 24px;
            color: #515151;
          }
        }
      }

      .form-action {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        gap: 15px;
        .action-btn {
          width: 160px;
          height: 40px;
          padding: 0px;
          color: #ff611d;
          background-color: #fff1eb;
          border-radius: 10px;
          border: none;
          outline: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 33.73px;
          text-align: center;
          cursor: pointer;
        }
        .action-btn-cancel {
          font-size: 14px;
          font-weight: 600;
          line-height: 33.73px;
          text-align: center;
          background-color: white;
          width: 160px;
          height: 40px;
          padding: 0px;
          color: #979797;
          border-radius: 10px;
          cursor: pointer;
          outline: none;
          border: 2px solid #979797;
        }
      }
    }
  }

  .message-container {
    width: 100%;

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      width: 90%;
      padding: 50px 8px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      .content {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          color: #ff611d;
          font-size: 30px;
        }
        p {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .action-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        button {
          width: 40%;
          max-width: 140px;
          height: 40px;
          padding: 0px;
          color: #ff611d;
          background-color: #fff1eb;
          border-radius: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 600;
          line-height: 33.73px;
          text-align: center;
          cursor: pointer;
          &:last-child {
            background-color: white;
            color: #979797;
            border: 2px solid #979797;
          }
        }
      }
      button {
        width: 70%;
        max-width: 140px;
        height: 40px;
        padding: 0px;
        color: #ff611d;
        background-color: #fff1eb;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 33.73px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .progress {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
