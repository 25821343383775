.stores-main {
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
}
.stores-header-div {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;
}
.stores-header-data-div {
  padding: 15px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  background-color: white;
}
.stores-header-txt-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
}
.stores-header-txt-div p {
  margin: 0px 5px;
  text-align: right;
}
.stores-header-btn {
  padding: 5px 20px;
  border: none;
  outline: none;
  border-radius: 10px;
  color: white;
  background-color: rgba(255, 97, 29, 1);
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
.stores-search-div {
  position: relative;
  width: 65%;
  border: 1px solid rgba(235, 235, 235, 1);
  border-radius: 8px;
}

.stores-search-input {
  padding: 5px 50px 5px 10px;
  min-height: 45px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
}
.stores-search-icon {
  position: absolute;
  top: 12.5px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: rgba(164, 164, 164, 1);
}

/***cards****/

.stores-cards-div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
}

.stores-card {
  /* width: 31%; */

  position: relative;
  width: 260px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
.stores-card img {
  width: 240px;
  height: 265px;
  object-fit: cover;
  object-position: center;
}
.stores-card-txt-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stores-card-txt-div p {
  margin: 0px 5px;
}
.stores-card p {
  font-size: 16px;
  font-weight: 600;
}
/******table******/
.stores-table {
  overflow: auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 0px 0px 10px;
}
.stores-table .stores-table-col {
  word-break: break-word;
  font-size: 20px;
}
.stores-table-head-row {
  display: flex;
  padding: 20px 15px;
  background-color: rgba(255, 244, 240, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.stores-table-row {
  padding: 10px 15px;
  background-color: rgba(244, 244, 244, 1);
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  display: flex;
}
.stores-table-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.stores-table-edit {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
  color: rgba(249, 166, 54, 1);
}

/****add material modal*****/

.add-material-modal .MuiBox-root {
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px !important;
  outline: none;
  background-color: white;
}
.add-material-modal-body {
  display: flex;
  flex-direction: column;
}
.add-material-modal-body .add-material-inputs {
  padding: 10px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 10px;
  border: none;
  outline: none;
  margin-top: 10px;
}
.add-material-modal-btns-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 10px;
}
.add-material-modal-addBtn,
.add-material-modal-cancelBtn {
  border-radius: 10px;
  outline: none;
  min-width: 160px;
  padding: 10px;
  margin: 0px 5px;
  cursor: pointer;
}
.add-material-inputs {
  color: rgba(81, 81, 81, 1);
  font-weight: 600;
}

.choose-img-btn {
  padding: 2px 15px;
  border: 1px solid rgba(255, 97, 29, 1);
  color: rgba(255, 97, 29, 1);
  border-radius: 10px;
  cursor: pointer;
}
.add-material-modal-addBtn {
  border: none;
  background-color: rgba(255, 241, 235, 1);
  color: rgba(255, 97, 29, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-material-modal-cancelBtn {
  border: 1px solid rgba(151, 151, 151, 1);
  color: rgba(151, 151, 151, 1);
}

/*****add link modal******/

.add-link-modal-fields-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.add-link-modal-select {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 1);
  border: none;
  outline: none;
  cursor: pointer;
}
.add-link-inputs {
  padding: 10px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 10px;
  border: none;
  outline: none;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}
@media all and (min-width: 767.1px) and (max-width: 900px) {
  .stores-header-btn {
    padding: 5px 10px;
    font-size: 16px;
  }
}

@media all and (max-width: 767px) {
  .stores-main {
    padding: 16px !important;
  }
  .stores-header-btn {
    font-size: 14px;
    padding: 5px;
  }
  .stores-header-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .stores-header-data-div {
    margin-top: 20px;
    min-width: 50%;
  }
  .stores-search-div {
    position: relative;
    width: 60%;
    border: 1px solid rgba(235, 235, 235, 1);
    border-radius: 8px;
  }

  .stores-card img {
    width: 100%;
    height: 265px;
    object-fit: cover;
  }
  .stores-search-div {
    margin-top: 20px;
    position: relative;
    width: 100%;
    border: 1px solid rgba(235, 235, 235, 1);
    border-radius: 8px;
  }
  .stores-header-txt-div {
    min-width: unset;
  }
  .add-material-modal .MuiBox-root {
    width: 70% !important;
  }
  .stores-table .stores-table-col {
    font-size: 16px;
  }
  .stores-cards-div {
    margin-top: 10px;
  }
  .stores-card {
    width: 100% !important;
  }
}
@media all and (max-width: 576px) {
  .stores-header-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .stores-header-data-div {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .stores-header-btn {
    min-width: 120px;
  }
  .add-material-modal .MuiBox-root {
    width: 80% !important;
  }
  .stores-table .stores-table-col {
    font-size: 16px;
  }
  .stores-search-div {
    margin-top: 20px;
    position: relative;
    width: 100%;
    border: 1px solid rgba(235, 235, 235, 1);
    border-radius: 8px;
  }
  .stores-cards-div {
    margin-top: 10px;
  }
}
