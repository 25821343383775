.datatable {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  gap: 0px !important;
  .datatable-header {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-link {
      text-decoration: none;
      color: white;
      background-color: #ff611d;
      font-size: 16px;
      font-weight: 400;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
    .date-container {
      display: flex;
    }

    .date-picker {
      margin-bottom: 0px;
      flex: 1;
    }
    .action-control {
      flex: 1;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;
    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .edit-button {
      text-decoration: none;
      width: 56px;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
      color: #f9a636;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
      &:hover {
        background-color: #f9a636;
        color: #fff;
        border: none;
      }
    }

    .delete-button {
      width: 56px;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
      &:hover {
        background-color: crimson;
        color: #fff;
        border: none;
      }
    }
  }

  .MuiDataGrid-columnHeaders {
    // overflow-x: auto;
    scrollbar-width: thin;
  }
}

.moadl-container {
  .form-container {
    width: 655px !important;

    height: auto;
    border-radius: 10px;
    padding: 0px !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100% !important;
      gap: 16px;
      .form-control {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        input {
          width: 587px;
          height: 50px;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: none;
          outline: none;
          padding-right: 14px;
          padding-left: 14px;
          font-size: 17px;
          font-weight: 600;
          line-height: 31.86px;
        }
        select {
          width: 615px;
          height: 50px;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: none;
          outline: none;
          padding-right: 14px;
          padding-left: 14px;
          font-size: 17px;
          font-weight: 600;
          line-height: 31.86px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }

      .form-action {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        gap: 15px;
        .action-btn {
          width: 208px;
          height: 54px;
          padding: 10px;
          color: #ff611d;
          background-color: #fff1eb;
          border-radius: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 600;
          line-height: 33.73px;
          text-align: center;
          cursor: pointer;
        }
        .action-btn-cancel {
          font-size: 18px;
          font-weight: 600;
          line-height: 33.73px;
          text-align: center;
          width: 208px;
          height: 54px;
          padding: 10px;
          color: #979797;
          border-radius: 10px;
          cursor: pointer;
          outline: none;
          border: 2px solid #979797;
        }
      }
    }
  }

  .progress-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .message-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .message {
      padding: 50px;
      background-color: white;
      border-radius: 10px !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      .content {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          color: #ff611d !important;
          font-size: 24px;
        }
        p {
          font-size: 24px;
          font-weight: 600;
        }
      }
      button {
        width: 208px;
        height: 54px;
        padding: 10px;
        color: #ff611d !important;
        background-color: #fff1eb !important;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .datatable {
    width: 100%;
    height: 100%;
    padding: 8px;
    .datatable-header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      .add-link {
        text-align: center;
        font-size: 8px;
        height: 18px !important;
      }
      .date-container {
        flex: 1 !important;
        display: flex;
        flex-direction: column;

        .date-picker {
          width: 100% !important;
          margin-bottom: 8px;
          width: 100%;
          div.ant-picker {
            width: 100%;
            height: 30px !important;
          }
        }
        .action-control {
          width: 100% !important;
          justify-content: space-between;
          button {
            font-size: 14px;
            height: 30px !important;
          }
        }
      }
    }

    .cellWithImg {
      display: flex;
      align-items: center;
      .cellImg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }
    }

    .cellWithStatus {
      padding: 5px;
      border-radius: 5px;

      &.active {
        background-color: rgba(0, 128, 0, 0.05);
        color: green;
      }
      &.pending {
        background-color: rgba(255, 217, 0, 0.05);
        color: goldenrod;
      }
      &.passive {
        background-color: rgba(255, 0, 0, 0.05);
        color: crimson;
      }
    }

    .cellAction {
      display: flex;
      align-items: center;
      // flex-direction: column;
      gap: 5px;

      .edit-button {
        text-decoration: none;
        width: 50px;
        text-align: center;
        padding: 2px;
        border-radius: 5px;
        color: #f9a636;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
        &:hover {
          background-color: #f9a636;
          color: #fff;
          border: none;
        }
      }

      .delete-button {
        width: 50px;
        text-align: center;
        padding: 2px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
        &:hover {
          background-color: crimson;
          color: #fff;
          border: none;
        }
      }
    }
  }

  .moadl-container {
    .form-container {
      width: 95% !important;
      height: auto;
      border-radius: 10px;
      padding: 0px !important;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      form {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100% !important;
        padding: 16px 8px;
        gap: 16px;
        .form-control {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          input {
            width: 95%;
            height: 50px;
            border-radius: 10px;
            background-color: #f5f5f5;
            border: none;
            outline: none;
            padding-right: 14px;
            font-size: 17px;
            font-weight: 600;
            line-height: 31.86px;
          }
          select {
            width: calc(95% + 16px);
            height: 50px;
            border-radius: 10px;
            background-color: #f5f5f5;
            border: none;
            outline: none;
            padding-right: 14px;
            font-size: 17px;
            font-weight: 600;
            line-height: 31.86px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }

        .form-action {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          gap: 15px;
          .action-btn {
            width: 120px;
            height: 40px;
            padding: 0px;
            color: #ff611d;
            background-color: #fff1eb;
            border-radius: 10px;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: 600;
            line-height: 33.73px;
            text-align: center;
            cursor: pointer;
          }
          .action-btn-cancel {
            width: 120px;
            font-size: 14px;
            font-weight: 600;
            line-height: 33.73px;
            text-align: center;
            height: 40px;
            padding: 0px;
            color: #979797;
            border-radius: 10px;
            cursor: pointer;
            outline: none;
            border: 2px solid #979797;
          }
        }
      }
    }

    .progress-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .message-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      .message {
        padding: 50px;
        background-color: white;
        border-radius: 10px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        .content {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            color: #ff611d !important;
            font-size: 24px;
          }
          p {
            font-size: 24px;
            font-weight: 600;
          }
        }
        button {
          width: 208px;
          height: 54px;
          padding: 10px;
          color: #ff611d !important;
          background-color: #fff1eb !important;
          border-radius: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
