.datatable {
  padding: 16px 16px 32px 16px;
  height: 600px;
  div.css-z8fhq1-MuiDataGrid-columnHeaders {
    background-color: #fff4f0;
  }

  .date-container {
    gap: 8px;
  }
  .date-picker {
    margin-bottom: 17px;
    display: flex;
    justify-content: flex-end;
    div.ant-picker {
      height: 40px;
    }
    div.ant-picker-input {
      input {
        &::placeholder {
          color: black;
        }
      }
    }
    .action-control {
      margin-right: 16px;
      display: flex;
      gap: 10px;
      button {
        border: none;
        outline: none;
        color: #1976d2;
        padding: 6px 8px;
        font-weight: 500;
        font-size: 0.875rem;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          background-color: rgba(25, 118, 210, 0.04);
        }
      }
    }
  }

  div.MuiDataGrid-footerContainer {
  }
  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: green;
      }
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }

    .editButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: orange;
      border: 1px solid orange;
      cursor: pointer;
    }
  }
}

.moadl-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .from-container {
    width: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    form {
      width: 100%;
      // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.6);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .control-group {
        width: 100%;
        display: flex;
        gap: 30px;
        .from-control {
          flex: 1;
          input {
            width: 100%;
            outline: none;
            border: none;
            border-bottom: 2px solid #008080;
            font-size: 18px;
            font-weight: 600;
            padding: 10px;
          }
          select {
            width: 100%;
            padding: 10px;
            font-size: 18px;
            font-weight: 600;
          }
        }
        textarea {
          width: 100%;
          font-size: 16px;
        }
      }

      .from-action {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        gap: 15px;
        .action-btn {
          width: 150px;
          font-size: 18px;
          font-weight: 600;
          border: none;
          outline: none;
          background-color: #008080;
          color: white;
          padding: 10px;
          // border-radius: 5px;
          cursor: pointer;
        }
        .action-btn-cancel {
          width: 150px;
          font-size: 18px;
          font-weight: 600;
          border: none;
          outline: none;
          background-color: red;
          color: white;
          padding: 10px;
          // border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .progress-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .message-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .message {
      padding: 50px;
      background-color: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      .content {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          color: green;
          font-size: 24px;
        }
        p {
          font-size: 24px;
          font-weight: 600;
        }
      }
      button {
        width: 150px;
        font-size: 18px;
        font-weight: 600;
        border: none;
        outline: none;
        background-color: #008080;
        color: white;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
}

div.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

div.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  justify-content: center;
}

.modal {
  position: relative;
  min-height: 100vh !important;
  z-index: 100000 !important;
  .single-order {
    position: absolute;
    height: 80% !important;
    width: 80%;
    padding: 32px 0px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
    .single-container {
      width: calc(100% - 32px);
      height: calc(100% - 32px);
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      scrollbar-width: thin;
      gap: 16px;
      .icon-container {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        .close-icon {
          font-size: 40px;
          &:hover {
            background-color: #ff611d;
            color: white;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
      .top {
        width: calc(100% - 16px);
        padding: 8px;
        display: flex;
        gap: 16px;
        .left {
          flex: 2;
          width: calc(100% - 32px);
          padding: 16px;
          background-color: #f2f2f2;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          border-radius: 10px;
          .item {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            .details {
              width: 100%;
              .itemTitle {
                margin-bottom: 10px;
                color: #555;
              }
              .detailItem {
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .itemKey {
                  font-weight: bold;
                  color: gray;
                  margin-right: 5px;
                }

                .itemValue {
                  font-weight: 300;
                }
              }
            }
          }
        }

        .right {
          flex: 1;
          width: calc(100% - 32px);
          padding: 16px;
          background-color: #f2f2f2;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          border-radius: 10px;
          .icon-container {
            position: absolute;
            right: -20px;
            .close-icon {
              font-size: 40px;
              &:hover {
                background-color: #ff611d;
                color: white;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
          .item {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            .details {
              width: 100%;
              .itemTitle {
                margin-bottom: 10px;
                color: #555;
              }
              .detailItem {
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .itemKey {
                  font-weight: bold;
                  color: gray;
                  margin-right: 5px;
                }

                .itemValue {
                  font-weight: 300;
                }
              }
            }
          }
        }
      }

      .bottom {
        width: calc(100% - 32px);
        padding: 16px;
        background-color: #f3f3f3;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      }

      .title {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      .styled-table {
        background-color: #f3f3f3;
        width: 100%;
        border-collapse: collapse;
        min-width: 400px;
        thead tr {
          background-color: #ff611d;
          color: #ffffff;
          text-align: center;
          font-size: 18px;
        }
        tbody {
          text-align: center;
          font-weight: bold;
          :hover {
            color: #ff611d;
            background-color: #f3f3f3;
            cursor: pointer;
          }
        }

        th,
        td {
          padding: 12px 15px;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
          }
          object-fit: cover;
        }

        tbody tr:last-of-type {
          border-bottom: 2px solid #ff611d;
        }

        .icon-delete {
          margin-right: 30px;
          color: red;
          font-size: 30px;
          cursor: pointer;
        }

        .icon-edit {
          margin-right: 30px;
          color: #ff611d;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

label.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  left: 40px;
}

@media (max-width: 767px) {
  .modal {
    .single-order {
      position: absolute;
      width: 80%;
      height: 80% !important;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: relative;
      .single-container {
        .icon-container {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 1;
          .close-icon {
            font-size: 16px;
            &:hover {
              background-color: #ff611d;
              color: white;
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }
        .top {
          flex-direction: column;

          .left {
            flex: 1;

            .item {
              .details {
                .itemTitle {
                }
                .detailItem {
                  .itemKey {
                  }

                  .itemValue {
                  }
                }
              }
            }
          }

          .right {
            .icon-container {
              .close-icon {
              }
            }
            .item {
              .details {
                .itemTitle {
                }
                .detailItem {
                  .itemKey {
                  }

                  .itemValue {
                  }
                }
              }
            }
          }
        }

        .bottom {
          width: calc(100% - 32px);
          padding: 16px;
          background-color: #f3f3f3;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }

        .title {
          font-size: 18px;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .styled-table {
          background-color: #f3f3f3;
          width: 100% !important;
          border-collapse: collapse;
          min-width: 100%;
          thead tr {
            background-color: #ff611d;
            color: #ffffff;
            text-align: center;
            font-size: 8px;
          }
          tbody {
            text-align: center;
            font-weight: bold;
            font-size: 8px;
            :hover {
              color: #ff611d;
              background-color: #f3f3f3;
              cursor: pointer;
            }
          }

          th,
          td {
            padding: 5px;
            width: fit-content;
            img {
              width: 20px;
              height: 20px;
              object-fit: cover;
              border-radius: 50%;
            }
            object-fit: cover;
          }

          tbody tr:last-of-type {
            border-bottom: 2px solid #ff611d;
          }

          .icon-delete {
            margin-right: 30px;
            color: red;
            font-size: 30px;
            cursor: pointer;
          }

          .icon-edit {
            margin-right: 30px;
            color: #ff611d;
            font-size: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .datatable {
    .date-picker {
      margin-bottom: 17px;
      margin-left: 8px;
      display: flex;
      justify-content: flex-end;
      div.ant-picker {
        height: 40px;
      }
      div.ant-picker-input {
        input {
          &::placeholder {
            color: black;
          }
        }
      }
    }
  }

  .single {
    position: absolute;
    height: 100% !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;

    background-color: #f3f3f3;
    .singleContainer {
      width: 100%;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px !important;
      .top {
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .left {
          background-color: #f3f3f3;
          flex: 1;
          width: calc(100% - 16px);
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 8px;
          position: relative;
          .icon-container {
            position: absolute;
            right: 0px !important;
            .close-icon {
              font-size: 40px;
              &:hover {
                background-color: #ff611d;
                color: white;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
          .item {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            .details {
              width: 100%;
              .itemTitle {
                margin-bottom: 10px;
                color: #555;
              }
              .detailItem {
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .itemKey {
                  font-weight: bold;
                  color: gray;
                  margin-right: 5px;
                }

                .itemValue {
                  font-weight: 300;
                }
              }
            }
          }
        }
      }

      .bottom {
        width: calc(100% - 16px) !important;
        background-color: #f3f3f3;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 8px;
        margin: 0px;
      }

      .title {
        font-size: 14px;
        color: lightgray;
        margin-bottom: 20px;
        text-align: left;
      }
      .styled-table {
        background-color: #f3f3f3;
        width: 100% !important;
        border-collapse: collapse;
        align-self: center;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 100% !important;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        thead tr {
          background-color: #ff611d;
          color: #ffffff;
          text-align: center;
          font-size: 12px !important;
        }
        tbody {
          text-align: center;
          font-weight: bold;
          :hover {
            color: #ff611d;
            background-color: #f3f3f3;
            cursor: pointer;
          }
        }

        th,
        td {
          font-size: 12px !important;
          padding: 12px 15px;
          img {
            width: 50px !important;
            height: 50px !important;
            object-fit: cover;
            border-radius: 50%;
          }
          object-fit: cover;
        }

        tbody tr:last-of-type {
          border-bottom: 2px solid #ff611d;
        }

        .icon-delete {
          margin-right: 30px;
          color: red;
          font-size: 30px;
          cursor: pointer;
        }

        .icon-edit {
          margin-right: 30px;
          color: #ff611d;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
