.list {
  display: flex;
  width: 100%;

  .listContainer {
    width: 100%;
    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
