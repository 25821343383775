.main {
  .main-container {
    padding-top: 50px;
    display: flex;
    .main-menu {
      width: 250px;
      min-height: 100vh;
    }
    .main-content {
      flex: 1;
      min-height: 100vh;
      background-color: #f9f9f9;
    }
  }
}

@media (max-width: 1024px) {
  .main {
    .main-container {
      display: flex;
      .main-menu {
        width: 50px;
      }
      .main-content {
        flex: 1;
        background-color: #f9f9f9;
      }
    }
  }
}
