.home {
  .homeContainer {
    .widgets {
      display: flex;
      padding: 20px;
      gap: 20px;
      .widget {
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding: 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        min-height: 100px;

        .left,
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-weight: bold;
            font-size: 14px;
            color: rgb(160, 160, 160);
          }

          .counter {
            font-size: 28px;
            font-weight: 300;
          }

          .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
          }

          .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
              color: green;
            }
            &.negative {
              color: red;
            }
          }

          .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
          }
        }
      }
    }

    .charts {
      display: flex;
      gap: 20px;
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    .homeContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      .widgets {
        display: flex;
        flex-direction: column;
        width: calc(100% - 16px);
        padding: 8px;
        gap: 20px;
        .widget {
          display: flex;
          justify-content: space-between;
          flex: 1;
          padding: 10px;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          border-radius: 10px;
          min-height: 100px;
          .left,
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              font-weight: bold;
              font-size: 14px;
              color: rgb(160, 160, 160);
            }

            .counter {
              font-size: 28px;
              font-weight: 300;
            }

            .link {
              width: max-content;
              font-size: 12px;
              border-bottom: 1px solid gray;
            }

            .percentage {
              display: flex;
              align-items: center;
              font-size: 14px;

              &.positive {
                color: green;
              }
              &.negative {
                color: red;
              }
            }

            .icon {
              font-size: 18px;
              padding: 5px;
              border-radius: 5px;
              align-self: flex-end;
            }
          }
        }
      }

      .charts {
        display: flex;
        width: calc(100% - 16px);
        padding: 8px;
        gap: 20px;
      }

      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 8px;
        margin: 8px;

        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
          font-size: 12px;
        }
      }
    }
  }
}
