.datatable {
  padding: 16px 16px 32px 16px;
  height: 600px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 16px !important;
  .wrapper {
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    .date-picker {
      flex: 1 !important;
      margin-bottom: 0px !important;
      height: 50px;
      div.ant-picker {
        height: 100%;
        flex: 1;
      }
    }
    .react-selector {
      flex: 1 !important;
      border: none;
      outline: none;

      .css-13cymwt-control {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: white;
        border: none;
        outline: none;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .css-1xc3v61-indicatorContainer {
        // display: none;
        color: black;
      }
      .css-1jqq78o-placeholder {
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

      .css-1hb7zxy-IndicatorsContainer {
        margin-left: 4px;

        .css-tj5bde-Svg {
          width: 24px;
          height: 24px;
          color: #515151;
        }
      }
    }

    .item {
      flex: 1;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-radius: 10px;
      background-color: white;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .action-control {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    button {
      width: 120px;
      font-size: 16px;
      padding: 0px;
      height: 40px;
      border-radius: 6px;
      outline: none;
      border: none;
      background-color: #ff611d;
      color: white;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .datatable {
    flex-direction: column !important;
    gap: 8px !important;
    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      .date-picker {
        width: 100%;
        margin-left: 0px;

        div.ant-picker {
          flex: auto;
          height: 40px !important;
        }
      }
      .react-selector {
        flex: 1 !important;
        border: none;
        outline: none;
        width: 100%;
        order: 1;
        .css-13cymwt-control {
          width: 100%;
          height: 40px !important;
          border-radius: 10px;
          background-color: white;
          border: none;
          outline: none;
        }

        .css-1u9des2-indicatorSeparator {
          display: none;
        }
        .css-1xc3v61-indicatorContainer {
          // display: none;
          color: black;
        }
        .css-1jqq78o-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: black;
        }

        .css-1hb7zxy-IndicatorsContainer {
          margin-left: 4px;

          .css-tj5bde-Svg {
            width: 24px;
            height: 24px;
            color: #515151;
          }
        }
      }

      .item {
        width: calc(100% - 16px);
        line-height: 40px;
      }
    }
    .action-control {
      justify-content: space-between;
      button {
        flex: 1;
      }
    }
  }
}
