.login {
  position: relative;
  width: 100% !important;
  min-height: 100vh !important;
  background-image: url(https://res.cloudinary.com/dmymq7zql/image/upload/v1710068092/ASUS/fenwteovu1trke7vpmwf.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffeee6;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 10%);
    img {
      width: 178px;
      height: 178px;
      object-fit: cover;
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 44.98px;
      text-align: center;
    }
    form {
      width: 500px !important;
      padding: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .second-icon {
          position: absolute;
          color: #c1c1c1;
          font-size: 28px;
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.5);
          }
        }
        input {
          width: calc(100% - 20px);
          outline: none;
          border: none;
          line-height: 40px;
          padding: 0px 10px;
          font-size: 18px;
          font-weight: 600;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 16%);
          border-radius: 5px;
          &::placeholder {
            color: #c1c1c1;
          }
        }
      }
      .form-action {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 100%;
          border-radius: 5px;
          outline: none;
          border: none;
          line-height: 40px;
          padding: 0px 10px;
          font-size: 18px;
          font-weight: 600;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 16%);
          background-color: #ff611d;
          color: white;
          cursor: pointer;
          &.disabled {
            cursor: not-allowed;
            background-color: #ff611d;
            color: #ff611d;
          }
        }
        .circular-progress {
          position: absolute;
        }
      }
      .error {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        .icon {
          color: red;
        }
      }
    }
  }
  .language {
    position: absolute;
    right: 16px;
    top: 0;
    border: none !important;
    outline: none !important;
    .ReactFlagsSelect-module_selectBtn__19wW7 {
      border: none !important;
    }
    .ReactFlagsSelect-module_label__27pw9 {
      padding-right: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .login {
    width: 100% !important;
    .container {
      width: calc(90% - 20px) !important;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #ffeee6;
      padding: 24px 10px;
      border-radius: 20px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 10%);
      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 44.98px;
        text-align: center;
      }
      form {
        width: calc(100% - 20px) !important;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        .form-control {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          .second-icon {
            position: absolute;
            color: #c1c1c1;
            font-size: 28px;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.5);
            }
          }
          input {
            width: calc(100% - 20px);
            outline: none;
            border: none;
            line-height: 40px;
            padding: 0px 10px;
            font-size: 18px;
            font-weight: 600;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 16%);
            border-radius: 5px;
            &::placeholder {
              color: #c1c1c1;
            }
          }
        }
        .form-action {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            width: calc(100% + 20px);
            border-radius: 5px;
            outline: none;
            border: none;
            line-height: 40px;
            padding: 0px 10px;
            font-size: 18px;
            font-weight: 600;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 16%);
            background-color: #ff611d;
            color: white;
            cursor: pointer;
            &.disabled {
              cursor: not-allowed;
              background-color: #ff611d;
              color: #ff611d;
            }
          }
          .circular-progress {
            position: absolute;
          }
        }
        .error {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 5px;
          .icon {
            color: red;
          }
        }
      }
    }
  }
}
