/* Remove arrows from input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  direction: var(--direction);
}

@media (max-width: 756px) {
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    font-size: 8px;
  }
}
