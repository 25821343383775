.tabels {
  width: 100%;

  .tabels-container {
    width: calc(100% - 140px);
    padding: 44px 70px;
    display: flex;
    flex-direction: column;

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .action-btn {
        width: 233px;
        height: 48px;
        border-radius: 10px;
        background-color: #ff611d;
        border: none;
        outline: none;
        color: white;
        font-size: 20px;
        font-weight: 500;
        line-height: 37.48px;
        cursor: pointer;
      }
    }

    .wrapper {
      width: 100%;
      padding: 50px 0px;
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      align-items: center;

      .item {
        cursor: pointer;
        width: 300px;
        height: 176px;
        border-radius: 10px;
        background-color: white;
        position: relative;
        .item-actions {
          position: absolute;
          left: 0;
          top: -16px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          .icon {
            background-color: white;
            border-radius: 5px;
            padding: 4px;
            font-size: 24px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
            cursor: pointer;
            &:first-child {
              color: #f9a636;
            }
            &:last-child {
              color: #bc2a46;
            }
          }
        }
        .item-header {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffeee6;
          border-radius: 10px;
          span {
            font-size: 17px;
            font-weight: 600;
            line-height: 31.86px;
          }
        }
        .item-body {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .body-line {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 17px;
              font-weight: 600;
              line-height: 31.86px;
            }
          }
        }
      }
    }
  }
}

.single {
  position: absolute;
  height: 100% !important;
  width: 100%;
  padding-top: 50px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;

  background-color: white;
  background-color: #eee;
  .close-icon {
    position: absolute;
    right: 40px;
    font-size: 40px;
    &:hover {
      background-color: #ff611d;
      color: white;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .singleContainer {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    padding: 50px;

    .single-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions {
        display: flex;
        align-items: center;
        gap: 16px;
        .action-btn {
          width: 160px;
          height: 48px;
          border-radius: 10px;
          background-color: #ff611d;
          border: none;
          outline: none;
          color: white;
          font-size: 20px;
          font-weight: 500;
          line-height: 37.48px;
          cursor: pointer;
          &:first-child {
            background-color: white;
            color: #ff611d;
            border: 2px solid #ff611d;
          }
        }
      }
      .item {
        width: 300px;
        border-radius: 10px;
        background-color: white;
        .item-body {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .body-line {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 17px;
              font-weight: 600;
              line-height: 31.86px;
            }
          }
        }
      }
    }

    .styled-table {
      background-color: #f3f3f3;
      width: 100%;
      border-collapse: collapse;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      thead tr {
        background-color: #ff611d;
        color: #ffffff;
        text-align: center;
        font-size: 18px;
      }
      tbody {
        text-align: center;
        font-weight: bold;
        :hover {
          color: #ff611d;
          background-color: #f3f3f3;
          cursor: pointer;
        }
      }

      th,
      td {
        padding: 12px 15px;
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 50%;
        }
        object-fit: cover;
      }

      tbody tr:last-of-type {
        border-bottom: 2px solid #ff611d;
      }

      .icon-delete {
        margin-right: 30px;
        color: red;
        font-size: 30px;
        cursor: pointer;
      }

      .icon-edit {
        margin-right: 30px;
        color: #ff611d;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .tabels {
    width: 100%;

    .tabels-container {
      width: calc(100% - 32px);
      padding: 16px !important;
      display: flex;
      flex-direction: column;

      .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .action-btn {
          width: fit-content;
          height: 40px;
          border-radius: 10px;
          background-color: #ff611d;
          border: none;
          outline: none;
          color: white;
          font-size: 14px;
          font-weight: 500;
          line-height: 37.48px;
          cursor: pointer;
        }
      }

      .wrapper {
        width: 100%;
        padding: 50px 0px;
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        align-items: center;

        .item {
          cursor: pointer;
          width: 100%;
          height: 176px;
          border-radius: 10px;
          background-color: white;
          position: relative;
          .item-actions {
            position: absolute;
            left: 0;
            top: -18px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            .icon {
              background-color: white;
              border-radius: 5px;
              padding: 4px;
              font-size: 24px;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
              cursor: pointer;
              &:first-child {
                color: #f9a636;
              }
              &:last-child {
                color: #bc2a46;
              }
            }
          }
          .item-header {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffeee6;
            border-radius: 10px 10px 0px 0px;
            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 31.86px;
            }
          }
          .item-body {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .body-line {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 14px;
                font-weight: 600;
                line-height: 31.86px;
              }
            }
          }
        }
      }
    }
  }

  .single {
    position: absolute;
    height: 100% !important;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 50px;
    z-index: 1000;
    display: flex;

    background-color: white;
    background-color: #eee;
    .close-icon {
      position: absolute;
      right: 0px;
      font-size: 30px;
      &:hover {
        background-color: #ff611d;
        color: white;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .singleContainer {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      padding: 8px;
      .single-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        .actions {
          display: flex;
          align-items: center;
          gap: 16px;
          .action-btn {
            width: 160px;
            height: 48px;
            border-radius: 10px;
            background-color: #ff611d;
            border: none;
            outline: none;
            color: white;
            font-size: 20px;
            font-weight: 500;
            line-height: 37.48px;
            cursor: pointer;
            &:first-child {
              background-color: white;
              color: #ff611d;
              border: 2px solid #ff611d;
            }
          }
        }
        .item {
          width: 100%;
          border-radius: 10px;
          background-color: white;
          .item-body {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .body-line {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 17px;
                font-weight: 600;
                line-height: 31.86px;
              }
            }
          }
        }
      }

      .styled-table {
        background-color: #f3f3f3;
        width: 100%;
        border-collapse: collapse;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        thead tr {
          background-color: #ff611d;
          color: #ffffff;
          text-align: center;
          font-size: 18px;
        }
        tbody {
          text-align: center;
          font-weight: bold;
          :hover {
            color: #ff611d;
            background-color: #f3f3f3;
            cursor: pointer;
          }
        }

        th,
        td {
          padding: 12px 15px;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
          }
          object-fit: cover;
        }

        tbody tr:last-of-type {
          border-bottom: 2px solid #ff611d;
        }

        .icon-delete {
          margin-right: 30px;
          color: red;
          font-size: 30px;
          cursor: pointer;
        }

        .icon-edit {
          margin-right: 30px;
          color: #ff611d;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
